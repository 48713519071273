import * as React from "react"
import { CaseStudySectionCard } from "./CaseStudySectionCard"
import CaseStudySectionCardTwo from "./CaseStudySectionCardTwo"
import Container from "./Container"
import { useStaticQuery, graphql } from 'gatsby'

const CaseStudyPageSection = () => {
  const data = useStaticQuery(graphql`
    query CaseStudySectionCardQuery {
      allContentfulCaseStudy {
        edges {
          node {
            id
            image {
              file {
                url
              }
            }
            companyIcon {
              file {
                url
              }
            }
            author {
              image {
                file {
                  url
                }
              }
            }
            summary
            title
            slug
            topicDescription
          }
        }
      }
    }
  ` 
  )
  const caseStudySectionCardData = data.allContentfulCaseStudy.edges 
  
  return (
    <Container>
      {caseStudySectionCardData.map((caseCard: any) => {
        return(
          <CaseStudySectionCard
            key={caseCard.node.id}
            CaseStudyImage = {caseCard?.node?.image?.file?.url ? caseCard.node.image.file.url : ''}
            CompanyLogo = {caseCard?.node?.companyIcon?.file?.url ? caseCard.node.companyIcon.file.url : ''}
            Body = {caseCard.node.summary}
            TopicDescription = {caseCard.node.topicDescription}
            AuthorImg = {caseCard?.node?.author?.image?.file?.url ? caseCard.node.author.image.file.url : ''}
            Author = {caseCard.node.author.name}
            Url={`/case-study/${caseCard.node.slug}`}
          />
        )
       })} 
    </Container>
  )
}

export default CaseStudyPageSection