import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { CaseStudiesPageQueryQuery } from '../../types/graphql-types' // eslint-disable-line import/no-unresolved
import CaseStudiesSection from '../components/CaseStudiesSection'
import CaseStudyPageSection from '../components/CaseStudyPageSection'

type Props = {
  data: CaseStudiesPageQueryQuery
}

const CaseStudiesLandingPage: React.FC<Props> = ({ data }: Props) => {

  return (
    <Layout>
      <SEO title="Case Studies" />
      <CaseStudiesSection/>
      <CaseStudyPageSection/>
    </Layout>
  )
}

// export const query = graphql`
//   query CaseStudiesPageQuery {
//     allContentfulLandingPage(filter: { title: { eq: "Case Studies" } }) {
//       edges {
//         node {
//           id
//           title
//           body {
//             json
//           }
//         }
//       }
//     }
//   }
// `

export default CaseStudiesLandingPage

