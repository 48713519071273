import React from 'react'
import styled from 'styled-components'
import Container from './Container'
import { RoughNotation } from "react-rough-notation"
import { Link } from 'gatsby'
import {device, colors} from './styles/GlobalStyles'

import Book from "../assets/images/book-open-2.png"

const CaseStudiesSection = () => {
  return (
    <Container>
      <BookImgMobile src={Book}/>
      <SectionContainer>
        <DividerImg/>
        <TextWrapper>
          <HeadingH1 id='CaseStudySection'>
              Case&nbsp;
            <RoughNotation type ="highlight" show={true} color="#004CFF" animationDelay={200}>
              <b>
                Studies
              </b>    
            </RoughNotation>
          </HeadingH1>
          <Description>
            Discover how we address technical challenges for our clients. 
            Our case studies are written by the engineering team.
          </Description>
          <BinaryDividerText>
            01110101 01101110 01100100 01100101 01110010 01101100 01101001 01101110 0110010101110101 01101110 01100100 01100101 01110010 01101100 01101001 01101110 0110010101110101 01101110 01100100 01100101 01110010 01101100 01101001 01101110 0110010101110101 01101110 01100100 01100101 01110010 01101100 01101001 01101110 0110010101110101 01101110 01100100 01100101 01110010 01101100 01101001 01101110 
          </BinaryDividerText>
        </TextWrapper>
        <BookImg src={Book}/>
      </SectionContainer>
    </Container>
  )
}

export default CaseStudiesSection

const SectionContainer = styled.div`
  display: flex;
  color: ${colors.white};
  padding: 15px 0px 32px 0px;

  @media ${device.laptop}{
    padding: 32px 0px 72px 0px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: static;

  @media ${device.laptop} {
    width: 865px;
    flex: none;
    margin-left: 64px;
  }
`

const DividerImg = styled.div`
  border: 2px solid ${colors.green};
  height: 47px;
  margin: 5px 24px 0 0;

  @media ${device.laptop} {
    height: 65px;
    margin-top: 15px;
  }
  
`

const BookImg = styled.img`
  display: none;

  @media ${device.laptop} {
    display: flex;
    margin: 30px 0px 0px 130px;
    width: 90px;
    height: 90px;
    filter: drop-shadow(0px 4px 48px #0066FF);
  }
`

const BookImgMobile = styled.img`
  margin: 30px 0px 0px 0px;
  width: 45px;
  height: 45px;
  filter: drop-shadow(0px 4px 48px #0066FF);

  @media ${device.laptop} {
    display: none;
  }
`

const HeadingH1 = styled.h1`
  position: static;
  font-family: "Raleway";
  font-style: normal;
  font-size: 40px;
  font-weight: bold;
  line-height: 140%;
  margin: 0px;
  b { 
    font-family: "Raleway";
    font-style: normal;
    font-size: 40px;
    font-weight: bold;
  }

  @media ${device.laptop}{
    font-size: 70px;
    width: 760px;
    b { 
      font-family: "Raleway";
      font-style: normal;
      font-size: 70px;
      font-weight: bold;
    }
  }
`

const Description = styled.p`
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 140%;
  color: ${colors.grey};
  margin: 8px 0px 28px 0px;

  @media ${device.laptop}{
    font-size: 24px;
    width: 730px;
    margin: 16px 0px 40px 0px;
  }
 
`

const BinaryDividerText = styled.p`
  height: 4px;
  font-family: Source Code Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 3px;
  line-height: 148%;
  color: ${colors.darkPurple};
  overflow: hidden;
  margin: 0px 0px 0px -24px;

  @media ${device.laptop} {
    margin: 0px;
  }
`

const NavLink = styled(Link)`
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 140%;
  text-decoration-line: underline;
  color: ${colors.green};
  margin: 0px;

  @media ${device.laptop}{
    font-size: 24px;
  }
`