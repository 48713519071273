import { Link } from "gatsby"
import * as React from "react"
import styled from 'styled-components'
import {colors, device} from './styles/GlobalStyles'

import ArrowImg from "../assets/images/white-arrow.png"
import BgShapes2 from '../assets/images/shapes2.svg'

interface CardProps {
  CaseStudyImage?: string
  CompanyLogo?: string
  Body: string
  TopicDescription: string
  AuthorImg?: string
  Author: string
  Url: string
}

export const CaseStudySectionCard: React.FC<CardProps> = ({CaseStudyImage, CompanyLogo, Body, TopicDescription, AuthorImg, Author, Url}) => (
  <>
    <StyledContainer className="card" to={Url}>
      <StyledPhoto className="image" src={CaseStudyImage}/>
      <BackgroundCardContainer/>
      <CardContainer>
        <StyledLogo className="logo" src={CompanyLogo}/>
        <TextWrapper>
          <Description className="body">
            {Body}
          </Description>
          <ArrowIcon src={ArrowImg} />
        </TextWrapper>
        <Topic>
          {TopicDescription}
        </Topic>
        <BinaryDivider>
          0101010001101000011010010111001100100000011010010111001100100000011000010110111000100000011101010110111001100100011001010111001001101100011010010110111001100101
        </BinaryDivider>
        <EmployeeWrapper>
          <StyledAuthorPhoto src={AuthorImg}/>
          <EmployeeName>
            {Author}
          </EmployeeName>
        </EmployeeWrapper>
        <BgPicture src={BgShapes2}/>
      </CardContainer>
    </StyledContainer>
  </>
)

const BackgroundCardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 94.5%;
  height: 255px;
  margin: -160px 0px 0px 6px;
  background: ${colors.darkPurple};

  @media ${device.laptop}{
    width: 809px;
    height: 296px;
    margin: 32px 0px 0px -380px;
  }
`

const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 8px;
  width: 92.9%;
  height: 281px;
  margin: -250px 0px 0px 12px;
  background: ${colors.darkPurpleCard};
  overflow: visible;

  @media ${device.laptop}{
    padding: 24px 32px;
    width: 809px;
    height: 320px;
    margin: 42px 0px 0px -800px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const Description = styled.p`
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  color: ${colors.lightGrey};
  align-self: stretch;
  text-align: left;
  margin: 16px 0;

  @media ${device.laptop} {
    font-size: 21px;
    font-weight: 500;
    width: 600px;
    font-size: 36px;
    line-height: 128%;
    margin: 12px 0;
  }
`

const Topic = styled.p`
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 148%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${colors.grey};
  margin: 0 0 20px 0;
  text-align: left;

  @media ${device.laptop} {
    font-size: 14px;
    margin: 0 0 24px 0;
  }
`

const ArrowIcon = styled.img`
  width: 22.5px;
  height: 21px;
  margin: 40px 0px 0px 5px;

  @media ${device.laptop}{
    width: 30px;
    height: 28px;
    margin: 50px 0px 0px 90px;
  }
`

const StyledPhoto = styled.img`
  margin: 0px;
  mix-blend-mode: luminosity;

  @media ${device.laptop} {
    width: 867px;
    height: 489px;
  }
`

const StyledLogo = styled.img`
  position: static;
  margin: 0px;
  mix-blend-mode: luminosity;

  @media ${device.laptop}{
    width: 143.87px;
  }
`

const BinaryDivider = styled.p`
  font-family: Source Code Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 2px;
  line-height: 148%;
  color: ${colors.darkGrey};
  margin: 0px;
  overflow: hidden;
`

const StyledAuthorPhoto = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  float: left;
  margin: 0px;

  @media ${device.laptop}{
    width: 40px;
    height: 40px;
  }
`

const EmployeeName = styled.p`
  font-family: Source Code Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  margin: 0px 0px 0px 16px;

  @media ${device.laptop}{
    font-size: 16px;
  }
`

const EmployeeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 12px;

  @media ${device.laptop}{
    margin-top: 16px;
  }
`

const BgPicture = styled.img`
  width: 79px;
  height: 41.5px;
  margin: 0px 0px -15px 220px;

  @media ${device.laptop}{
    width: 158px;
    height: 83px;
    margin: -30px 0px 0px 570px;
  }
  
`

const StyledContainer = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 45px 0px;
  overflow: hidden;
  background: transparent;
  color: ${colors.white};
  cursor: pointer;
  text-decoration: none;

  @media ${device.laptop}{
    flex-direction: row;
    padding: 0px 0px 160px 0px;

    &:hover ${StyledPhoto} {
      mix-blend-mode: normal;
    }
  
    &:hover ${BackgroundCardContainer} {
      background: ${colors.green};
      margin: 40px 0px 0px -380px;
    }
  
    &:hover ${CardContainer} {
      margin: 56px 0px 0px -790px;
    }
  
    &:hover ${StyledLogo} {
      mix-blend-mode: normal;
    }
  
    &:hover ${Description} {
      color: ${colors.white};
    }
  }
`